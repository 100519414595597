import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "form",
    "omni",
    "code",
    "string",
    "boolean",
    "country",
    "submit"
  ];

  showAdvanced(event) {
    event.preventDefault();
    this.populateAdvanced();
    this.formTarget.classList.add("show-advanced");
  }

  showSimple(event) {
    event.preventDefault();
    this.formTarget.classList.remove("show-advanced");
  }

  resetEverything(event) {
    event.preventDefault();
    this.omniTarget.value = "";
    this.populateAdvanced();
    const omniValue = this.buildOmni().join(" ");
    if (omniValue == this.originalValue) {
      this.submitTarget.classList = "btn btn-default";
    } else {
      this.submitTarget.classList = "btn btn-secondary";
    }
  }

  connect() {
    this.originalValue = this.omniTarget.value;
    this.populateAdvanced();
  }

  populateAdvanced() {
    const words = this.omniTarget.value.split(" ");
    console.log(words);
    for (const target of this.stringTargets) {
      const name = target.dataset.name;
      const regexp = new RegExp(`^${name}:`);
      const list = [];
      for (const word of words) {
        if (regexp.test(word)) {
          list.push(word.replace(regexp, ""));
        }
      }
      target.value = list.join(" ");
    }
    for (const target of this.booleanTargets) {
      target.checked = false;
      const name = target.dataset.name;
      for (const word of words) {
        if (word === name) {
          target.checked = true;
        }
      }
    }
    if (this.hasCountryTarget) {
      this.countryTarget.selectedIndex = 0;
      const regexp = new RegExp(`^country:`);
      for (const word of words) {
        if (regexp.test(word)) {
          const code = word.replace(regexp, "").toUpperCase();
          for (const option of this.countryTarget.options) {
            if (code === option.value) {
              option.selected = true;
            }
          }
        }
      }
    }
    this.checkValid();
  }

  checkValid() {
    if (this.valid()) {
      this.formTarget.classList.remove("invalid");
    } else {
      this.formTarget.classList.add("invalid");
    }
  }

  populateOmni(event) {
    const omniValue = this.buildOmni().join(" ");
    this.omniTarget.value = omniValue;
    this.codeTarget.innerHTML = omniValue;
    this.checkValid();
    if (omniValue == this.originalValue) {
      this.submitTarget.classList = "btn btn-default";
    } else {
      this.submitTarget.classList = "btn btn-secondary";
    }
  }

  buildOmni(event) {
    const omni = [];
    for (const target of this.stringTargets) {
      const name = target.dataset.name;
      for (const word of target.value.split(" ")) {
        if (word.trim() != "") {
          omni.push(`${name}:${word}`);
        }
      }
    }
    for (const target of this.booleanTargets) {
      const name = target.dataset.name;
      if (target.checked) {
        omni.push(name);
      }
    }
    if (this.hasCountryTarget) {
      for (const option of this.countryTarget.selectedOptions) {
        if (option.value != "") {
          omni.push(`country:${option.value}`);
        }
      }
    }
    return omni;
  }

  valid() {
    const a = this.omniTarget.value
      .split(" ")
      .sort()
      .filter(w => w != "");
    const b = this.buildOmni().sort();
    return a.join(" ").toLowerCase() === b.join(" ").toLowerCase();
  }
}
